@import "../../../scss/variable.scss";

.checkbox {
    display: flex;
    cursor: pointer;
    align-items: center;

    &--disabled {
        @extend .checkbox;
        cursor: not-allowed;

        .checkbox__checked {
            background-color: #81060844;
            cursor: not-allowed;
        }
        .checkbox__icon {
            cursor: not-allowed;
            border: 0.5px solid #bababa44;
        }
    }

    &__icon {
        width: 30px;
        height: 30px;
        border: 0.5px solid $borderColor;
        border-radius: 1px;
        background-color: $white;
        padding: 2px;
    }

    &__checked {
        background-color: $buttonDarkRed;
        width: 24px;
        height: 24px;
        display: block;
        border-radius: 1px;
    }

    &__label {
        margin-bottom: 0;
        margin-left: 16px;
        font-weight: 500;
        font-size: 16px;
        color: $black;

        & > img {
            width: 16px;
        }
    }
    &__tick {
        padding-top: 5px;
    }
}
