@import "../../scss/variable.scss";

.filters {
    padding-right: 24px;

    &__seperator {
        border: 1px solid #ffffff77;
    }

    &__header {
        color: $white;
        font-size: 16px;
        font-weight: 900;
        --bs-accordion-active-bg: #810608;
        --bs-accordion-bg: #810608;
        --bs-accordion-color: #ffffff;
        --bs-accordion-btn-color: #ffffff;
        --bs-accordion-active-color: #ffffff;
        --bs-accordion-border-color: none;
        --bs-accordion-border-width: 0;
        --bs-accordion-btn-focus-box-shadow: none;
        --bs-accordion-btn-padding-x: 0;
        --bs-accordion-btn-icon: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA1SURBVHgB7ZOxCgAABESd//9njFIMkkHeJnqD60AFYvgZRnbLNMSLFkWIEXe5/KNq+RW5LlJjugwedxvzHgAAAABJRU5ErkJggg==);
        --bs-accordion-btn-active-icon: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAECAYAAACDQW/RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAdSURBVHgBzcwxAQAAAAEw9O+MEA4LMLowIIz8RQGHagQEQHXIQgAAAABJRU5ErkJggg==);
        --bs-accordion-btn-icon-transform: rotate(180deg);
        --bs-accordion-body-padding-x: 0;
        --bs-accordion-body-padding-y: 16px;
        --bs-accordion-btn-padding-y: 0;

        & .accordion-button {
            &:not(.collapsed) {
                &::after {
                    margin-bottom: 18px;
                }
            }
        }
    }

    &__item {
        --bs-accordion-active-bg: #810608;
        --bs-accordion-bg: #810608;
        --bs-accordion-border-color: none;
        --bs-accordion-border-width: 0;
        --bs-accordion-color: #ffffff;
        --bs-accordion-btn-color: #ffffff;
        --bs-accordion-active-color: #ffffff;
        --bs-accordion-body-active-bg: #ffffff;
        --bs-accordion-body-padding-y: 8px;
        --bs-accordion-btn-padding-y: 0;
        --bs-accordion-btn-padding-x: 8px;
        --bs-accordion-btn-focus-border-color: none;
        --bs-accordion-btn-focus-box-shadow: none;

        & > .accordion-item {
            margin-bottom: 20px;

            & .row {
                background-color: $white;
            }
        }
    }

    &__item-header {
        border-bottom: 1px solid #ffffff77;
        padding: 4px 0;
        position: relative;
        --bs-accordion-btn-icon: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA1SURBVHgB7ZOxCgAABESd//9njFIMkkHeJnqD60AFYvgZRnbLNMSLFkWIEXe5/KNq+RW5LlJjugwedxvzHgAAAABJRU5ErkJggg==);
        --bs-accordion-btn-active-icon: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAECAYAAACDQW/RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAdSURBVHgBzcwxAQAAAAEw9O+MEA4LMLowIIz8RQGHagQEQHXIQgAAAABJRU5ErkJggg==);
    }

    &__header-icon {
        margin-right: 8px;
    }

    &__checkbox {
        margin: 8px 0;
    }
}
