@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.pointer-cursor {
    cursor: pointer;
}

.pointer-disabled {
    cursor: not-allowed !important;
}

.justify-end {
    display: flex;
    justify-content: flex-end;
}

.border-yellow {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-radius: 0;
}
