@import "../../scss/variable.scss";

.form-wrapper {
    height: 100%;
    overflow: auto;

    h2 {
        font-size: 24px;
        font-weight: 400;

        @media screen and (max-width: 990px) {
            font-size: 18px;
        }
    }

    &__box {
        border: 1px solid $primaryGrey;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top: 0;
    }

    &__border {
        height: 10px;
        background: $buttonDarkRed;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    &__modal-body {
        background-color: transparent;
        border: none;
    }

    &__modal {
        z-index: 99999;
    }
}
