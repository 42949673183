@import "../../../src/scss/variable.scss";

.inactive-submission {
    text-align: center;
    text-decoration: underline;
    & > p {
        margin: 10px auto;
        color: $buttonDarkRed;
        font-weight: 900;

        & > span {
            color: $black;
            padding-left: 4px;
        }
    }
}
