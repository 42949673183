@import "../../scss/variable.scss";
.loader {
    position: relative;
    display: flex;
    flex-grow: 1;
    height: 100%;
    align-items: center;
    justify-content: center;

    &__icon {
        color: $albaikRed;

        &--sm {
            --bs-spinner-width: 24px;
            --bs-spinner-height: 24px;
        }

        &--lg {
            --bs-spinner-width: 40px;
            --bs-spinner-height: 40px;
        }

        &--xl {
            --bs-spinner-width: 52px;
            --bs-spinner-height: 52px;
        }
    }
}
