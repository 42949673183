@import "../../../scss/variable.scss";

.custom-toggle {
    background: $toggleGreen;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;
    width: 100px;
    height: 34px;
    cursor: pointer;

    &--small {
        width: 80px;
    }

    @media screen and (max-width: 1024px) {
        width: 82px;
        border-radius: 41px;
    }

    &--off {
        @extend .custom-toggle;
        background: $red;

        @media screen and (max-width: 1024px) {
            width: 82px;
            border-radius: 41px;
        }
    }

    &--disabled {
        filter: opacity(0.5);
        cursor: not-allowed;
    }

    &__on,
    &__off {
        color: $white;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        flex-grow: 1;
        justify-content: center;
    }

    &__off {
        padding-right: 8px;
    }

    &__on {
        padding-left: 12px;
    }

    &__icon-on {
        padding: 2px;
    }

    &__icon-off {
        padding: 2px;
    }
}
