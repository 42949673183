@import "../../scss/variable.scss";

.selectSalesChannel {
    @media screen and (max-width: 1199px) {
        max-width: 100%;
    }

    &__label {
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        color: $black;
        margin-bottom: 20px;
        display: flex;
    }

    &__header {
        border-radius: 7px;
        height: 50px;
        background: linear-gradient(180deg, $paleRed 0%, $gradientOrange 100%);
        box-shadow: 0px 1px -1px $black;
        --bs-gutter-x: 0;

        &__content {
            height: 16px;
            padding-top: 14px;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: $pureWhite;
        }
    }
    &__page {
        height: calc(100vh - 245px);
        overflow: auto;
    }
    &__rowWrap {
        height: 70px;
        border: 1px solid $rowBorder;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: $primaryBlack;
        --bs-gutter-x: 0;
        border-radius: 7px;
        margin-top: 7px;

        &__grey-bg {
            background: $lightGrey;
        }
    }

    &__column {
        padding-left: 23px;
        padding-top: 10px;
        padding-bottom: 6px;
    }
    &__button-wrapper {
        position: fixed;
        bottom: 0;
        width: -webkit-fill-available;
        padding-right: 43px;
        padding-bottom: 20px;
        background-color: $white;
    }
    &__error {
        font-weight: 600;
        font-size: 16px;
        line-height: 14px;
        color: $buttonRed;
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        & > img {
            height: 12px;
            margin-right: 8px;
            margin-left: 10px;
        }
    }
}

.selectProducts {
    &__select-assembly-station {
        padding-bottom: 15px;
        padding-right: 100px;
    }
    &__chicken-icon {
        padding-right: 15px;
        height: 45px;
        width: 65px;
    }
}
