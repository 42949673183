@import "../../../../scss/variable.scss";

.add-customization {
    width: 100%;
    margin-top: 20px;
    border-top: 10px solid #810608;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #e3e3e3;
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;

    &__heading {
        font-family: "Roboto";
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;

        color: #000000;
    }
}
