@import "../../../scss/variable.scss";

.autocomplete-input {
    cursor: pointer;

    &__label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $black;
    }

    &__input {
        border: 1px solid $borderColor;
        border-radius: 3px;
        height: 50px;
    }

    &__error {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: $buttonRed;
        display: flex;
        align-items: center;

        & > img {
            height: 12px;
            margin-right: 8px;
        }
    }
}
