@import "../../../scss/variable.scss";

.splash-screen {
    background: $albaikRed;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > img {
        width: 46%;
        height: auto;
        animation: loading 0.75s infinite;
        transition: all 0.1s;
    }

    & > h3 {
        font-size: 36px;
        font-weight: 600;
        color: $white;
        margin-top: 88px;
        margin-bottom: 0;
    }
}

@keyframes loading {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.01);
    }

    50% {
        transform: scale(1.02);
    }

    75% {
        transform: scale(1.01);
    }

    100% {
        transform: scale(1);
    }
}
