@import "../../../scss/variable.scss";

.text-input {
    &__label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $black;
    }

    &__input {
        background: $white;
        border: 1px solid $borderColor;
        border-radius: 3px;
        height: 50px;
        width: 100%;

        &::placeholder {
            font-style: italic;
            font-weight: 300;
            font-size: 14px;
            color: $fadeBlack;
        }

        & > .react-time-picker__wrapper,
        .react-date-picker__wrapper {
            border: none;
            padding: 6px 12px;
        }

        &.react-time-picker--disabled,
        &.react-date-picker__wrapper {
            background-color: #e9ecef;
            opacity: 1;
        }
    }

    &__error {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: $buttonRed;
        display: flex;
        align-items: center;

        & > img {
            height: 12px;
            margin-right: 8px;
        }
    }
}
