@import "../../../../scss/variable.scss";

.app-layout {
    &__wrapper {
        overflow: hidden;
    }
}
.selectMachine {
    &__wrapper {
        overflow-y: auto;
        height: calc(100vh - 220px);
    }
    &__accordian {
        --bs-accordion-active-bg: none;
        --bs-accordion-bg: #810608;
        --bs-accordion-btn-focus-border-color: none;
        --bs-accordion-btn-focus-box-shadow: none;
        --bs-accordion-active-color: none;
        --bs-accordion-btn-color: #ffffff;
        --bs-accordion-btn-padding-x: 20px;
        --bs-accordion-btn-icon: url("../../../../assets/Icons/downArrowIcon.png");
        --bs-accordion-btn-active-icon: url("../../../../assets/Icons/downArrowIcon.png");
    }

    &__rowWrap {
        --bs-gutter-x: 0;
        align-items: center;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        color: #2e2e2e;
        font-size: 14px;
        font-weight: 500;
        height: 50px;
        line-height: 16px;
        margin-top: 5px;
        @media screen and (max-width: 900px) {
            font-size: 12px;
        }
    }
    &__rowGrey {
        background: #f4f4f4;
    }

    &__header {
        color: $pureWhite;
        font-size: 16px;
        font-weight: 700;
        border: 1px solid $darkRed;
        border-radius: 4px;
    }
    &__header-label {
        font-size: 16px;
        font-weight: 700;
    }
    &__body {
        background-color: $pureWhite;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $primaryGrey;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    &__item {
        border-radius: 4px;
    }
    &__addMachineCTA {
        margin-right: 55px;
        > button {
            width: 206px;
        }
    }
    &__enablepointer {
        pointer-events: auto;
        cursor: pointer;
    }
    &__noDataFound {
        margin-top: 30px;
        margin-left: 40%;
    }
    &__inline-error {
        color: $darkRed;
        margin-bottom: 4px;
        font-size: 18px;
    }
    &__nextBtn {
        margin-top: 20px;
        margin-right: 16px;
    }
}

.modal-content {
    .loading-button {
        &--submit {
            width: 146px;
        }
        &--add {
            width: 260px;
            &:disabled {
                background-color: $buttonDarkRed;
            }
            &:focus-visible {
                background-color: $buttonDarkRed;
                box-shadow: none;
            }
        }
    }
}
