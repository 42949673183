@import "../../scss/variable.scss";

.loading-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 160px;
    height: 50px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 20px;
    border: none;
    outline: none;

    &--reverse {
        flex-direction: row-reverse;
    }

    &--cancel {
        --bs-btn-active-color: #810608;
        --bs-btn-active-bg: #ffffff;

        background: $white;
        color: $darkRed;
        border: none;

        &:hover {
            background: $white;
            color: $darkRed;
        }
        &:disabled {
            background: $backgroundColor;
            cursor: not-allowed;
            pointer-events: all;
            color: $darkRed;

            &:hover {
                background: $backgroundColor;
                color: $darkRed;
            }
        }
    }

    &--submit {
        --bs-btn-active-color: #000000;
        --bs-btn-active-bg: #ffc425;

        background: $buttonYellow;
        color: $black;

        &:hover {
            background: $buttonYellow;
            color: $black;
        }

        &:disabled {
            background: $disableYellow;
            cursor: not-allowed;
            pointer-events: all;
            color: $white;

            &:hover {
                background: $disableYellow;
                color: $white;
            }
        }
    }

    &--add {
        --bs-btn-active-bg: #810608;
        --bs-btn-active-color: #ffffff;

        background: $buttonDarkRed;
        color: $white;

        &:hover {
            background: $buttonDarkRed;
            color: $white;
        }
    }

    &--upload {
        --bs-btn-active-bg: #f58025;
        --bs-btn-active-color: #ffffff;

        background-color: $orange;
        color: $white;
        margin-left: -12px;

        &:hover {
            background: $orange;
            color: $white;
        }

        &:disabled {
            background: $disabledOrange;
            cursor: not-allowed;
            pointer-events: all;
            color: $white;

            &:hover {
                background: $disabledOrange;
                color: $white;
            }
        }
    }
    &--back {
        --bs-btn-active-bg: #f58025;
        --bs-btn-active-color: #ffffff;

        background-color: $white;
        color: $darkRed;
        border: $darkRed;
        border-style: solid;
        border-width: thin;

        &:hover {
            background: $orange;
            color: $white;
        }

        &:disabled {
            background: $disabledOrange;
            cursor: not-allowed;
            pointer-events: all;
            color: $white;

            &:hover {
                background: $disabledOrange;
                color: $white;
            }
        }
    }
}
