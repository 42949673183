@import "../../../../scss/variable.scss";

.branches {
    &__rowWrap {
        --bs-gutter-x: 0;
        align-items: center;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        color: $primaryBlack;
        font-size: 14px;
        font-weight: 500;
        height: 50px;
        line-height: 16px;
        margin-top: 5px;
        @media screen and (max-width: 900px) {
            font-size: 12px;
        }
    }

    &--pl20 {
        @media screen and (min-width: 900px) {
            padding-left: 20px;
        }
        @media screen and (max-width: 900px) {
            padding-left: 12px;
        }
    }
    &--noData {
        color: $darkRed;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-top: 15%;
    }

    &__enablepointer {
        pointer-events: auto;
        cursor: pointer;
    }
    &__rowGrey {
        background: #f4f4f4;
    }

    &--page {
        height: calc(100vh - 310px);
        overflow-y: auto;
    }
    &--isScroll {
        margin-right: -20px;
        padding-right: 12px;
    }
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background-color: FFF;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #bababa;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.add-branch {
    &__viewPasskeyBtns {
        margin-top: 10px;
        justify-content: end;
        display: flex;
        margin-right: 0px;
        &:first {
            width: 160px !important;
        }
    }

    &__copyIcon {
        right: 10px;
        top: 35px;
        position: absolute;
        width: 60px;
        height: 34px;
        cursor: pointer;
    }
    &__viewPassKeyField {
        position: relative;
    }
    &__manageAccessBtn {
        font-size: 17px;
        padding: 0;
    }
}
