@import "../../../scss/variable.scss";

.app-layout {
    display: flex;
    height: 100vh;

    &__actions {
        display: flex;
        justify-content: flex-end;
    }

    &__logoff {
        background: $brightYellow;
        border-radius: 50%;
        padding: 12px;
        cursor: pointer;
    }

    &__label {
        font-size: 16px;
        margin-right: 24px;
    }

    &__badge {
        position: absolute;
        padding: 2px 6px;
        border-radius: 4px;
        background: $paleRed;
        font-size: 16px;
        font-weight: 600;
        color: $white;
        top: -4px;
        left: 24px;
    }

    &__sidebar {
        width: 350px;
        background: $darkRed;
        height: 100vh;
        padding: 128px 0px 60px 48px;
        transition: width 0.3s ease-in-out;
        overflow: auto;
        z-index: 1;

        &--collapsed {
            @extend .app-layout__sidebar;
            width: 100px;
            overflow: hidden;
        }

        @media screen and (max-width: 1200px) {
            position: absolute;
        }
    }

    &__arrows {
        cursor: pointer;
        transition: transform 0.2s ease-in-out;
        position: absolute;
        right: 20px;
        top: 0;

        &--reverse {
            @extend .app-layout__arrows;
            transform: rotate(180deg);
        }
    }

    &__sidebar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;
        margin-bottom: 16px;
        position: relative;
        min-height: 24px;

        & > p {
            margin: 0;
            color: $white;
            font-weight: 600;
            font-size: 16px;
        }
    }

    &__sidebar-link {
        display: flex;
        text-decoration: none;
        color: $white;
        padding: 10px 12px;
        transition: background 0.5s ease-in-out;
        border-radius: 30px 0px 0px 30px;
        background: transparent;
        outline: none;
        border: none;
        width: 100%;
        padding-right: 0;

        &:hover {
            color: $white;
        }

        &--active {
            @extend .app-layout__sidebar-link;
            background: $white;
            transition: background 0.5s ease-in-out;
        }
    }

    &__sidebar-icon {
        border: 1px solid $darkRed;
        background: $white;
        color: $darkRed;
        padding: 12px;
        height: 30px;
        width: 30px;

        &--yellow {
            @extend .app-layout__sidebar-icon;
            background: $brightYellow;
            color: $black;
        }
    }

    &__sidebar-label {
        color: $darkRed;
        font-weight: 700;
        transition: color 0.5s ease-in-out;
        transition: font-weight 0.2s ease-in-out;

        &--hidden {
            opacity: 0;
            transition: 0.05s opacity ease-out;
            max-height: 35px;
        }

        &--shown {
            opacity: 1;
            transition: 1s opacity ease-in;
            max-height: 35px;
            line-height: 1;
        }

        &--yellow {
            @extend .app-layout__sidebar-label;
            color: $brightYellow;
        }
    }

    &__wrapper {
        padding: 128px 28px 28px 28px;
        width: 100%;
        height: 100vh;

        @media screen and (max-width: 1200px) {
            padding-left: 128px;
        }
    }

    &__modal-icon {
        height: 80px;
        width: 80px;
    }
}
