@import "../../scss/variable.scss";

.app-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    background: $white;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 10px 10px;
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    & > img {
        @media screen and (max-width: 900px) {
            width: 100px;
        }
    }

    &__title {
        flex-grow: 1;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $paleRed;

        &--secondary {
            @extend .app-header__title;
            color: $black;
        }
    }

    &__end {
        min-width: 10.6%;
    }
}
