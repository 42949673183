@import "../../../../scss/variable.scss";

.my-branches {
    &__rowWrap {
        --bs-gutter-x: 0;
        align-items: center;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        color: $primaryBlack;
        font-size: 14px;
        font-weight: 500;
        height: 70px;
        line-height: 16px;
        margin-top: 5px;
        @media screen and (max-width: 900px) {
            font-size: 12px;
        }
    }

    &--pl20 {
        @media screen and (min-width: 900px) {
            padding-left: 20px;
        }
        @media screen and (max-width: 900px) {
            padding-left: 12px;
        }
    }
    &--noData {
        color: $darkRed;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-top: 15%;
    }

    &__enablepointer {
        pointer-events: auto;
        cursor: pointer;
    }
    &__rowGrey {
        background: #f4f4f4;
    }

    &--page {
        height: calc(100vh - 310px);
        overflow: auto;
    }
}
