@import "../../scss/variable.scss";

.image-upload {
    &__input {
        display: none;
    }

    &__uploaded-image {
        height: 75px;
        width: 105px;

        @media screen and (max-width: 930px) {
            margin-left: 4px;
        }
    }

    &__button {
        width: 160px;
        height: 50px;
        background: $orange;
        border-radius: 4px;
        border: $orange;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: $white;
        margin-left: -12px;
        margin-top: 2px;

        &--change {
            @media screen and (max-width: 930px) {
                margin-left: -15px;
                margin-top: 2px;
            }
        }

        &:hover {
            background: $orange;
            border: $orange;
            color: $white;
        }

        &:active {
            background: $orange !important;
            border: $orange !important;
            color: $white !important;
        }
    }

    &__image-name {
        @media screen and (max-width: 930px) {
            padding-left: 40px;
        }

        @media screen and (max-width: 875px) {
            padding-left: 40px;
        }
    }

    &__png-image-validation {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: $paleRed;
        margin-left: -12px;
        margin-top: 5px;
        white-space: nowrap;
    }
}
