@import "../../scss/variable.scss";

$base-class: "popupmodel";

.#{$base-class} {
    width: auto;

    &__header {
        background-color: $buttonRed;
        color: $fadeWhite;
        height: 40px;
        padding-right: 14px;
        font-size: 25px;
        vertical-align: middle;
    }

    &__headercontent {
        font-size: 18px;
        font-weight: 400;
        padding-left: 3px;
    }

    &__bodybutton {
        margin: 10px;
    }

    &__bodycontainer {
        flex-wrap: wrap;
        order: 7;
        display: flex;
        height: auto;
        width: auto;
        justify-content: center;
        align-items: center;
    }

    &__bodyitem {
        background-color: $pureWhite;
        border: 2px solid $fadedWhite;
        color: $paleBlack;
        border-radius: 6px;
        margin: 8px;
        width: 60px;
        height: 45px;

        &:hover {
            background-color: $buttonRed;
            color: $coalWhite;
            border-color: $buttonRed;
        }

        &:disabled {
            border: 1px solid $coalBlack;
            background-color: $lightCoalWhite;
            color: $greyed;
        }
    }

    &__bodyitemBorderOnly {
        background-color: $deepWhite;
        border: 2px solid $buttonRed;
        color: $paleBlack;
        border-radius: 6px;
        margin: 8px;
        width: 60px;
        height: 45px;

        &:hover {
            background-color: $deepWhite;
            color: $coalWhite;
            border-color: $buttonRed;
        }
    }

    &__bodyitemSelected {
        color: $paleWhite;
        border-radius: 6px;
        margin: 8px;
        width: 60px;
        height: 45px;
        background-color: $buttonRed;
        border: $blackBorder;
    }

    &__bodycontent {
        text-align: center;
        padding: 0px;
        color: $black;
    }

    &__footerContainer {
        width: 100%;
        text-align: -webkit-center;
    }
    &__btNone {
        border-top: none !important;
    }
    &__button {
        width: 170px;
        height: 50px;
        margin-right: 30px;
        font-size: 20px !important;
        font-weight: 600 !important;
        &--doneBtn {
            background: $buttonYellow !important;
            color: $black !important;
            border-color: $buttonYellow !important;

            &:disabled {
                background: $disableYellow !important;
                border-color: $disableYellow !important;
                cursor: not-allowed;
                pointer-events: all;
            }

            &:active {
                background: $buttonYellow !important;
                color: $black;
                border-color: $buttonYellow !important;
            }
        }

        &--cancelBtn {
            background: $buttonDarkRed !important;
            color: $pureWhite;
            border-color: $buttonDarkRed;

            &:active {
                background: $buttonDarkRed !important;
                color: $pureWhite;
                border-color: $buttonDarkRed !important;
            }
        }
    }
}

.custom-trayselectionButton {
    background-color: $pureWhite;
    color: $black;
    border-color: $black;
    border-radius: 2;
    --bs-btn-hover-bg: $buttonRed;
}

.popupmodel__header .btn-close {
    color: $pureWhite;
}
