@import "../../../scss/variable.scss";
.tanjara {
    &__container {
        margin: 0;
        border-radius: 3px;
        border: 1px solid $borderColor;
        padding: 47px;
    }
    &__maxNumber {
        label {
            visibility: hidden;
        }
    }
    &__selection {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 49px;
        border-radius: 10px;
        background: $borderColorOffWhite;
        padding: 0;
        width: 73px;
        margin-left: 17px;
    }
    &__selectedSlot {
        border: 2px solid #ed3b24;
    }
    &__go {
        position: absolute;
        top: 6px;
        left: 10px;
    }
}
.loading-button {
    position: relative;
}
