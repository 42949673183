@import "../../scss/variable.scss";

.table {
    background: linear-gradient(180deg, $paleRed 0%, $gradientOrange 100%);
    box-shadow: 0px 1px -1px $black;
    border-radius: 6px;
    min-height: 50px;
    margin: 0 0 5px 0;
    &__content {
        padding: 14px 0px 0px 0px;
        text-align: left;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: $pureWhite;
        @media screen and (max-width: 900px) {
            font-size: 14px;
        }
    }
    &__doubleArrow {
        min-height: 12px;
        padding-left: 5px;
        margin-bottom: 2px;
    }

    &__asc-Desc-Arrow {
        margin-bottom: 2px;
        height: 8px;
        padding-left: 5px;
    }
    &__icon-column {
        cursor: auto;
    }
    &__cursorPointer {
        cursor: pointer;
    }
    @media screen and (min-width: 900px) {
        &__first-label {
            padding-left: 18px;
        }
    }
    @media screen and (max-width: 900px) {
        &__first-label {
            padding-left: 10px;
        }
    }
}
