// Colors
$backgroundColor: #fbf8f8;
$white: #fff;
$transparentWhite: #ffffff4d;
$offwhite: #ffcfa9;
$borderColor: #bababa;
$buttonRed: #ec3826;
$buttonGreen: #84c630;
$buttonYellow: #ffc425;
$buttonGrey: #494949;
$buttonDarkRed: #810608;
$buttonDarkRedBorder: #880a0a;
$headerBackGroundRed: red;
$headerBackGroundOrange: orange;
$headerBackGroundColor: #595959;
$borderColorOffWhite: rgb(229, 229, 230);
$peach: rgb(240, 231, 231);
$offWhite: #fbf8f8;
$red: #de413a;
$darkRed: #810608;
$cornRowbackgroundColor: rgb(233, 233, 118);
$paleYellow: #fff8e4;
$palerYellow: #beee7177;
$orange: #f58025;
$palePink: #ffe3e0;
$paleRed: #ec3826;
$borderGrey: #e0e0e0;
$paleGrey: #777575;
$brightYellow: #ffcc00;
$black: #000000;
$pureWhite: #ffffff;
$lightGrey: #f4f4f4;
$lightGrey1: #efefef;
$darkGrey: #dadada;
$beige: #ffe3e0;
$lightBeige: #fff8e4;
$primaryBlack: #2e2e2e;
$gradientGreen: #bff56d;
$gradientYellow: #ffd25f;
$gradientOrange: #ff7001;
$loginYellow: #ffc425;
$primaryGrey: #e3e3e3;
$rowBorder: #e3e3e3;
$disableYellow: #e2c473;
$grey: #808080;
$blackBorder: #100f0f;
$paleWhite: #f5f5f8;
$deepWhite: #f1eded;
$paleBlack: #161617;
$fadeWhite: #f8f8f8;
$fadedWhite: #e8e4e4;
$coalWhite: #eaebed;
$lightCoalWhite: #e3dcdc;
$coalBlack: #0e0c0c;
$greyed: #ebe5e5;
$palerWhite: #00000005;
$borderWhite: #00000033;
$albaikRed: #da4834;
$toggleGreen: #5fcb09;
$fadeBlack: #1e1e1e;
$disabledOrange: #e1a06e;
$paleGrey2: #f3f3f3;
// Icons
$plus: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA1SURBVHgB7ZOxCgAABESd//9njFIMkkHeJnqD60AFYvgZRnbLNMSLFkWIEXe5/KNq+RW5LlJjugwedxvzHgAAAABJRU5ErkJggg==);
