@import "../../scss/variable.scss";
.pagination {
    &__container {
        width: 100%;
        display: flex;
        gap: 20px;
        list-style: none;
        justify-content: center;
        padding: 10px;
        &--disabled {
            pointer-events: none;
            color: $paleGrey;
        }

        li {
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
        }
        & input {
            width: 50px;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
        }
    }
    &__input-container {
        padding-top: 1px;
    }
    &__mt4 {
        margin-top: 4px;
    }
}
