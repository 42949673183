@import "../../scss/variable.scss";

.viewHeader {
    &__utilityContainer {
        margin: 0px 0px 18px 0px;
        height: 50px;
    }

    &__search {
        padding-right: 0;
        padding-left: 0;
        > div {
            height: 50px;
            > span {
                min-width: 53px;
            }
        }
        > span {
            background-color: $borderColor;
        }
    }
    &__rowSelection {
        justify-content: flex-end;
        display: flex;
        select {
            min-height: 50px;
            font-size: 14px;
            font-weight: 600;
            background-color: $lightGrey;
            border-color: $lightGrey;
            background-size: 24px;
            padding-right: 44px;
            padding-left: 17px;
        }
    }
    &__rowSelectionLabel {
        padding: 12px 10px 0 0;
        color: $paleGrey;
    }
    &__add-button {
        display: flex;
        gap: 10px;
        font-weight: 600;
        width: fit-content;
        font-size: 18px;
        & img {
            padding-bottom: 6px;
        }
    }
}
