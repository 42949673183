@import "../../scss/variable.scss";

.avatar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__label {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        margin-right: 4px;
        text-align: left;
    }

    &__icon {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;

        &-menu {
            background-image: linear-gradient(180deg, $paleRed 0%, $darkRed 100%);
            color: $white;
            width: 35px;
            height: 35px;
        }

        &-logout {
            background-image: linear-gradient(180deg, $brightYellow 0%, $brightYellow 100%);
            color: $black;
            width: 50px;
            height: 50px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    &--reverse {
        @extend .avatar;
        flex-direction: row-reverse;
        justify-content: flex-end;

        & > .avatar__icon {
            margin-right: 8px;
        }
    }
}

.custom_popover {
    --bs-popover-max-width: none;
    --bs-popover-border-color: #ffcc00;
    border-width: 3px;
    &__header {
        text-align: center;
        font-size: 14px;
        background-color: $paleGrey2;
        border-bottom: none;
        --bs-popover-inner-border-radius: none;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
    }
    &__email {
        color: $paleGrey;
        font-size: 14px;
    }
}
.bs-popover-bottom > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: $brightYellow;
}
